export const userMenu = {
  	dashboard: {
		id: 'dash',
		text: '대시보드',
		path: '/links/dash',
		icon: 'ri-dashboard-line',
		url: 'https://dx.idrenvision.com:8071',
		subMenu: null,
	},
  	analysis: {
		id: 'analysis',
		text: '융합분석',
		path: '/links/analysis',
		icon: 'ri-dashboard-line',
		url: 'https://dash.teamply.co.kr',
		subMenu: null,
	},
	// notice: {
	// 	id: 'notice',
	// 	text: '공지사항',
	// 	path: '/notice',
	// 	icon: 'ri-broadcast-line',
	// 	subMenu: null,
	// },
	// board: {
	// 	id: 'board',
	// 	text: '게시판',
	// 	path: '/board',
	// 	icon: 'ri-draft-line',
	// 	subMenu: null,
	// },
	chat: {
		id: 'chat',
		text: '채팅',
		path: '/chat/direct',
		icon: 'ri-message-2-line',
		subMenu: null,
	},
	favorite: {
		id: 'favorite',
		text: '즐겨찾는 채팅',
		path: '/chat/favorite',
		icon: 'ri-bookmark-3-line',
		hide: true,
		subMenu: null,
	},
	group: {
		id: 'group',
		text: '그룹채팅',
		path: '/chat/group',
		icon: 'ri-group-line',
		hide: true,
		subMenu: null,
	},
	anomalydetection: {
		id: 'anomalydetection',
		text: '이상감지',
		path: '/links/anomalydetection',
		icon: 'ri-article-line',
		subMenu: {
			anomalydetection: {
				id: 'anomalydetection',
				text: '기준설정',
				path: '/links/anomalydetection',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			alarmHistoryReport: {
				id: 'alarmHistoryReport',
				text: 'Alarm History Report',
				path: '/links/alarmHistoryReport',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			warningChart: {
				id: 'warningChart',
				text: 'Warning Chart',
				path: '/links/warningChart',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
		}
	},
	monitoring: {
		id: 'monitoring',
		text: '생산모니터링',
		path: '/links/monitoring',
		icon: 'ri-cast-line',
		url: 'https://dx.idrenvision.com:8071',
	},
	FactoryPerformance: {
		id: 'FactoryPerformance',
		text: '생산정보',
		path: '/links/FactoryPerformance',
		icon: 'ri-article-line',
		subMenu: {
			workDailyReport: {
				id: 'workDailyReport',
				text: '생산일보',
				path: '/links/workDailyReport',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			workDailySummary: {
				id: 'workDailySummary',
				text: '생산일보 Summary',
				path: '/links/workDailySummary',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			inventory: {
				id: 'inventory',
				text: '재고관리',
				path: '/links/inventory',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
		},
	},
	simulator: {
		id: 'simulator',
		text: 'What-if Simulation',
		path: '/links/simulator',
		icon: 'ri-slideshow-line',
		url: 'https://dx.idrenvision.com:8071',
		subMenu: null,
	},
	// settings: {
	// 	id: 'settings',
	// 	text: '설정',
	// 	path: '/settings',
	// 	icon: 'ri-settings-4-line',
    // 	hide: true,    
	// 	subMenu: {
	// 		profileSettings: {
	// 			id: 'profileSettings',
	// 			text: '프로필',
	// 			path: '/settings/profileSettings',
	// 			icon: 'ri-account-box-line',
	// 			subMenu: null,
    //     hide: true,
	// 		},
	// 		notificationSettings: {
	// 			id: 'notificationSettings',
	// 			text: '알림',
	// 			path: '/settings/notificationSettings',
	// 			icon: 'ri-notification-line',
	// 			subMenu: null,
    //     hide: true,
	// 		},
	// 	},
	// },
};

export const adminMenu = {
	FactoryPerformanceAnalysis: {
		id: 'FactoryPerformanceAnalysis',
		text: '기준정보 관리',
		path: '/links/FactoryPerformanceAnalysis',
		icon: 'ri-survey-line',
		subMenu: {
			groupshift: {
				id: 'groupshift',
				text: '작업 기준정보',
				path: '/links/groupshift',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			materialmaster: {
				id: 'materialmaster',
				text: '자재 기준정보',
				path: '/links/materialmaster',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			supplierinfo: {
				id: 'supplierinfo',
				text: '업체별 담당자 정보',
				path: '/links/supplierinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			factoryinfo: {
				id: 'factoryinfo',
				text: '회사정보',
				path: '/links/factoryinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			productInformation: {
				id: 'productInformation',
				text: '제품정보',
				path: '/links/productInformation',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			lineinfo: {
				id: 'lineinfo',
				text: '공정정보',
				path: '/links/lineinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			storageinfo: {
				id: 'storageinfo',
				text: '창고정보',
				path: '/links/storageinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			equipmentinfo: {
				id: 'equipmentinfo',
				text: '설비정보',
				path: '/links/equipmentinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
			workerinfo: {
				id: 'workerinfo',
				text: '작업자정보',
				path: '/links/workerinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
				subMenu: null,
			},
		}
	},
}

export const adminMenu2 = {
	factoryinfo: {
		id: 'factoryinfo',
		text: '기준정보관리',
		path: '/links/factoryinfo',
		icon: 'ri-article-line',
		url: 'https://dx.idrenvision.com:8071',
		subMenu: {
			factoryinfo: {
				id: 'factoryinfo',
				text: '회사정보',
				path: '/links/factoryinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			supplierinfo: {
				id: 'supplierinfo',
				text: '공급업체정보',
				path: '/links/supplierinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			materialmaster: {
				id: 'materialmaster',
				text: '자재마스터',
				path: '/links/materialmaster',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			mvinfo: {
				id: 'mvinfo',
				text: '모델/차종정보',
				path: '/links/mvinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			groupshift: {
				id: 'groupshift',
				text: '작업구분',
				path: '/links/groupshift',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
		},
	},
	workerinfo: {
		id: 'workerinfo',
		text: '공장정보관리',
		path: '/links/workerinfo',
		icon: 'ri-article-line',
		url: 'https://dx.idrenvision.com:8071',
		subMenu: {
			workerinfo: {
				id: 'workerinfo',
				text: '작업자정보',
				path: '/links/workerinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			equipmentinfo: {
				id: 'equipmentinfo',
				text: '설비정보',
				path: '/links/equipmentinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			storageinfo: {
				id: 'storageinfo',
				text: '창고정보',
				path: '/links/storageinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			logisticsinfo: {
				id: 'logisticsinfo',
				text: '물류정보',
				path: '/links/logisticsinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			lineinfo: {
				id: 'lineinfo',
				text: '공정정보',
				path: '/links/lineinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			lotinfo: {
				id: 'lotinfo',
				text: 'LOT정보',
				path: '/links/lotinfo',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
		},
	},
	dvcmodel: {
		id: 'dvcmodel',
		text: '모델링정보',
		path: '/links/dvcmodel',
		icon: 'ri-article-line',
		url: 'https://dx.idrenvision.com:8071',
		subMenu: {
			dvcmodel: {
				id: 'dvcmodel',
				text: 'DVC DataSet 모델링',
				path: '/links/dvcmodel',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			datasetdetail: {
				id: 'datasetdetail',
				text: '분류 DataSet 상세매핑',
				path: '/links/datasetdetail',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			scenario: {
				id: 'scenario',
				text: '시나리오별 그룹핑',
				path: '/links/scenario',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
			dxcube: {
				id: 'dxcube',
				text: 'DX Value Chain Cube',
				path: '/links/dxcube',
				icon: 'ri-article-line',
				url: 'https://dx.idrenvision.com:8071',
			},
		},
	},
}

export const auth = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		icon: 'Login',
		subMenu: null,
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: '/sign-up',
		icon: 'PersonAdd',
		subMenu: null,
	},
	findPassword: {
		id: 'findPw',
		text: 'Find Password',
		path: '/find-password',
		icon: 'PersonAdd',
		subMenu: null,
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '/auth-pages/404',
		icon: 'ReportGmailerrorred',
		subMenu: null,
	},
};
